import React from 'react'
import { injectIntl } from 'gatsby-plugin-intl'
import Seo from './seo'

const PageContainer = props => (
  <>
    <Seo />
    {props.children}
  </>
)

export default injectIntl(PageContainer)
