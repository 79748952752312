import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

class Rail {
  connectors = {}
  constructor() {
    this.takeUntil$ = new Subject()
    this.onHover$ = new Subject()
    this.onReset$ = new Subject()
  }
  createSubject(name) {
    this.connectors[name] = new Subject()
  }
  createTakeUntil() {
    const sub$ = new Subject()
    return { handler: takeUntil(sub$), emit: sub$ }
  }
}
export default new Rail()
