import React, { Component } from 'react'
import './Cursor.styl'
import Rail from './../../Providers/Rail'

export default class Cursor extends Component {
  state = {
    x: 0,
    y: 0,
    w: 20 + 'px',
    h: 20 + 'px',
    prevX: 0,
    style: {},
    prevY: 0,
    onMove: false,
    onScroll: false,
    lastScrollPosition: 0,
    status: 'normal',
  }
  currentTarget = null

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.moveHandler, true)
    window.removeEventListener('click', this.clickHandler)
    this.takeUntil$.emit.next()
  }

  componentDidMount() {
    this.takeUntil$ = Rail.createTakeUntil()

    document.body.style.cursor = 'none'
    Rail.onHover$.pipe(this.takeUntil$.handler).subscribe(next => {
      if (next.type === 'onHeaderLink') {
        this.setState({
          w: next.target.clientWidth + 'px',
          h: next.target.clientHeight + 'px',
          y: next.target.offsetTop + 'px',
          x: next.target.offsetLeft + 'px',
          status: 'headerLink',
        })
      }
    })

    Rail.onReset$.pipe(this.takeUntil$.handler).subscribe(() => {
      this.setState({
        w: 20,
        h: 20,
        status: 'normal',
      })
    })

    window.addEventListener('mousemove', this.moveHandler, true)

    window.addEventListener('click', this.clickHandler)
  }

  clickHandler = () => {
    if (!this.ref) return false
    this.ref.classList.add('onClick')
    setTimeout(() => {
      if (this.ref) this.ref.classList.remove('onClick')
    }, 300)
  }
  moveHandler = evt => {
    this.currentTarget = evt.target
    this.setState(prevState => ({
      x: evt.clientX,
      y: evt.clientY,
      style: {
        left: evt.clientX + 'px',
        top: evt.clientY + 'px',
        width: prevState.w,
        height: prevState.h,
      },
      prevX: prevState.x,
      prevY: prevState.y,
      onMove: prevState.x === evt.clientX,
    }))
  }

  render() {
    return (
      <div
        style={this.state.style}
        ref={e => (this.ref = e)}
        className={`Cursor ${this.state.status}`}
      />
    )
  }
}
