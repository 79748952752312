import React from 'react'
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl'
import './Footer.styl'
import invLogo from './../Assets/logo_inversee.svg'
import fbLogo from './../Assets/fb.png'
import instaLogo from './../Assets/instagram.png'
import bookingLogo from './../Assets/booking.png'
const Footer = () => (
  <footer className='Footer'>
    <div>
      <div>
        <img alt='' src={invLogo} />
        <address>
          <span>Societa Agricola Klanjscek</span>
          <span>Loc. Ossario 13/c</span>
          <span>Gorizia(GO) </span>
          <span>34170 Italia</span>
          <span>C.F/P.IVA:01174870319</span>
        </address>
      </div>
      <div>
        <h3>
          <FormattedMessage id='prenotation' />
        </h3>
        <div>
          <div>
            <FormattedMessage id='phone' />:{' '}
            <a att='' href='tel:0481280900'>
              0481 280900
            </a>
          </div>
          <div>
            Email:{' '}
            <a att='' href='mailto:info@klanjscek.it'>
              info@klanjscek.it
            </a>
          </div>
        </div>
        <nav>
          <a
            target='_blank'
            title='facebook'
            href='https://www.facebook.com/AgriturismoKlanjscek/'
          >
            <img alt='' src={fbLogo} />
          </a>
          <a
            target='_blank'
            title='instagram'
            href='https://www.instagram.com/klanjscekwineandstay/?hl=it'
          >
            <img alt='' src={instaLogo} />
          </a>
          <a
            target='_blank'
            title='booking'
            href='https://www.booking.com/hotel/it/klanjscek-wine-amp-stay.it.html?aid=898224&app_hotel_id=4656073&checkin=2019-03-29&checkout=2019-03-30&from_sn=ios&group_adults=2&group_children=0&label=Share-7tffO1%401552551640&no_rooms=1&req_adults=2&req_children=0&room1=A%2CA%2C'
          >
            <img alt='' src={bookingLogo} />
          </a>
        </nav>
      </div>
    </div>
  </footer>
)

export default injectIntl(Footer)
