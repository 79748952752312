import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import Header from './header'
import Footer from './footer'
import Cursor from '../UI/Cursor/Cursor'
import Helmet from 'react-helmet'
import PageContainer from './pageContainer'
import { injectIntl } from 'gatsby-plugin-intl'
import { ParallaxProvider } from 'react-scroll-parallax'
import { StaticQuery, graphql } from 'gatsby'
import './layout.css'
import './layout.styl'

const Content = props => (
  <ParallaxProvider>
    <section className='Content'>{props.children}</section>
  </ParallaxProvider>
)
class Layout extends Component {
  state = {
    isMenuOpen: false,
  }
  openMenu() {
    this.setState(prevState => ({ isMenuOpen: !prevState.isMenuOpen }))
  }
  render() {
    const { children } = this.props
    const locale = this.props.intl.locale
    return (
      <StaticQuery
        query={graphql`
          query siteQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <Helmet>
              <html lang={locale} />
              <meta name='theme-color' content='#fff' />
              <meta property='og:type' content='business.business' />
            </Helmet>
            <main>
              <Fragment>
                <Header
                  {...data.site.siteMetadata}
                  onOpenMenu={() => this.openMenu()}
                  isMenuOpen={this.state.isMenuOpen}
                />
                <PageContainer>
                  <Content>{children}</Content>
                </PageContainer>
                <Cursor />
                <Footer {...data.site.siteMetadata} />
              </Fragment>
            </main>
          </Fragment>
        )}
      />
    )
  }
}

export default injectIntl(Layout)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
