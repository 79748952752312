import React from 'react'
import PropTypes from 'prop-types'
import { Link, injectIntl } from 'gatsby-plugin-intl'
import locales from '../i18n/locales'

const LinkLang = ({ to, intl: { locale }, ...props }) => {
  const path = `/${to}`
  return (
    <Link
      onMouseOver={props.onMouseOver}
      onMouseOut={props.onMouseOut}
      {...props}
      className={`LinkLang ${props.className || ''}`}
      to={path}
    />
  )
}

LinkLang.propTypes = {
  to: PropTypes.string.isRequired,
}

export default injectIntl(LinkLang)
