import React from 'react'
import LinkLang from './LinkLang'
import { Location } from '@reach/router'
import './header.styl'
import Rail from './../Providers/Rail'
import LogoSVG from './../Assets/logo.webp'
import { injectIntl, FormattedMessage } from 'react-intl'
import locales from './../i18n/locales'

const Logo = () => (
  <div className='Logo'>
    <LinkLang children='' to='/' />
    <img src={LogoSVG} alt='Logo' />
  </div>
)

const handleHover = target => {
  Rail.onHover$.next({
    type: 'onHeaderLink',
    target: target,
  })
}

const handleOut = () => {
  Rail.onReset$.next()
}

const changeLanguage = (e, lang) => {
  e.preventDefault()
  const langs = Object.keys(locales)
  const def = langs.filter(item => locales[item].default)[0]
  const pathname = window.location.pathname.replace(/^\/[a-z]{2}\//, '/')
  localStorage.setItem('LANGUAGE', lang)
  let language = ''
  language = `/${lang}`
  const url = `${window.location.origin}${language}${pathname}`
  window.location.href = url
  return false
}

const Header = props => {
  const localesArray = Object.keys(locales)
  const locale = props.intl.locale
  return (
    <Location>
      {locationProps => {
        const currentPath = locationProps.location.pathname
        const checkActivePage = path => {
          return currentPath.match(path) ? 'active ' : ''
        }
        return (
          <div className={`Header ${props.isMenuOpen ? '_menuOpen' : ''}`}>
            <Logo />
            <div className='menu-links'>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/story')}
                to='/story'
              >
                <strong>
                  <FormattedMessage id='history' />
                </strong>
              </LinkLang>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/wines')}
                to='/wines'
              >
                <strong>
                  <FormattedMessage id='wine' />
                </strong>
              </LinkLang>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/rooms')}
                to='/rooms'
              >
                <strong>
                  <FormattedMessage id='rooms' />
                </strong>
              </LinkLang>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/cook')}
                to='/cook'
              >
                <strong>
                  <FormattedMessage id='cook' />
                </strong>
              </LinkLang>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/poi')}
                to='/poi'
              >
                <strong>
                  <FormattedMessage id='poi' />
                </strong>
              </LinkLang>
              <LinkLang
                onMouseOver={e => handleHover(e.currentTarget)}
                onMouseOut={e => handleOut(e)}
                className={checkActivePage('/aboutus')}
                to='/aboutus'
              >
                <strong>
                  <FormattedMessage id='aboutus' />
                </strong>
              </LinkLang>
            </div>
            <nav className='menu-lang'>
              {localesArray.map(lang => (
                <a
                  className={`${lang === locale ? 'active' : ''}`}
                  onClick={e => changeLanguage(e, lang)}
                  key={lang}
                >
                  {lang.toUpperCase()}
                </a>
              ))}
            </nav>
            <button onClick={props.onOpenMenu}>
              <i />
            </button>
          </div>
        )
      }}
    </Location>
  )
}

export default injectIntl(Header)
